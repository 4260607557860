module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M9ZMDCN","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","includeInDevelopment":false,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-M9ZMDCN","cookieName":"gatsby-gdpr-google-analytics","dataLayerName":"gatsby"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.efficientweb.pl/graphql","verbose":true,"develop":{"nodeUpdateInterval":3000000,"hardCacheMediaFiles":true,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":false,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":500,"requestConcurrency":50,"timeout":1000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"type":{"__all":{"limit":6000},"MediaItem":{"localFile":{"requestConcurrency":50,"maxFileSizeBytes":100000000,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EfficientWeb","short_name":"EfficientWeb","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#1b9ba9","display":"browser","icon":"static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d9af1ebdb2fdd40811ee939ceedcd043"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
