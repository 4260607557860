import React from 'react';
import { AppProvider } from "../components/context/AppContext";

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */
export const wrapRootElement = ( { element } ) => (
	<AppProvider>
		{ element }
	</AppProvider>
);
